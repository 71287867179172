import React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { Reset } from 'styled-reset'

import 'gatsby-plugin-prismic-previews/dist/styles.css'

import { GlobalStyles } from './src/global-styles'

export const wrapRootElement = ({ element }) => <PrismicPreviewProvider>{element}</PrismicPreviewProvider>

export const wrapPageElement = ({ element }) => (
  <>
    <Reset />
    <GlobalStyles />
    {element}
  </>
)
