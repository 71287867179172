module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-W7P9JM83SJ","cookieName":"gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Trust in Media","short_name":"Trust in Media","start_url":"/","icon":"src/images/icon.png","background_color":"#00cce5","theme_color":"#454aff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6eaf71a01dc22064f826fef804093e86"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"trust-in-media","accessToken":"MC5ZTXRZc0JJQUFDTUFpZlN4.77-9Ku-_vRt_Y--_ve-_ve-_ve-_vXh0TSsfR--_ve-_vVNbdO-_ve-_vXbvv71eM--_vV4277-9MQ","toolbar":"new","promptForAccessToken":true,"apiEndpoint":"https://trust-in-media.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"clt2bop"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
