const sizes = {
  mobile: '425px',
  tablet: '768px',
  laptop: '1025px',
  desktop: '1366px',
}

export const devices = {
  mobile: `screen and (min-width: ${sizes.mobile})`,
  tablet: `screen and (min-width: ${sizes.tablet})`,
  laptop: `screen and (min-width: ${sizes.laptop})`,
  desktop: `screen and (min-width: ${sizes.desktop})`,
}
