import styled from 'styled-components'
import { devices } from '../../lib/constants'
import watermark from '../../images/watermark.svg'

export const StyledLogo = styled.div`
  max-width: 100px;
  height: auto;
  flex: 1;
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transform-origin: 0 50%;

  @media ${devices.laptop} {
    max-width: 140px;
  }

  svg {
    max-width: 100%;
    vertical-align: middle;
  }
`

export const StyledHeader = styled.header`
  height: var(--menu-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem var(--spacer-medium);
  margin: var(--spacer-medium) 0 var(--spacer-large) 0;
  position: sticky;
  top: -1px;
  z-index: 100;

  &.is-sticky {
    transition: background 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    background: rgba(255, 255, 255, 1);

    @media ${devices.mobile} {
      ${StyledLogo} {
        transform: scale(0.75);
      }
    }
  }

  @supports (backdrop-filter: blur(12px)) {
    &.is-sticky {
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(12px);
    }
  }

  nav {
    margin-bottom: 0;

    @media ${devices.laptop} {
      margin-left: var(--spacer-large);
    }
  }

  .hero-offset &:not(.is-sticky) {
    nav {
      color: var(--color-light);

      a:hover {
        color: var(--color-secondary);
      }

      .current-page {
        color: var(--color-secondary);
      }
    }

    .cls-1 {
      fill: var(--color-light);
    }

    button {
      color: var(--color-light);
      border: 1px solid var(--color-light);

      > div {
        background: var(--color-light);

        &:before,
        &:after {
          background: var(--color-light);
        }
      }
    }
  }
`

export const StyledMain = styled.main`
  width: calc(100% - (var(--spacer-medium) * 2));
  max-width: var(--max-layout-width);
  margin: 0 auto;
  position: relative;
  z-index: 1;
  font-size: ${({ fontSize }) => fontSize && `clamp(2rem, 3vw, ${fontSize})`};
`

export const StyledNav = styled.nav`
  font-weight: var(--font-body-weight-medium);

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: var(--color-primary);
    }
  }

  .current-page,
  .current-cat {
    color: var(--color-primary);
  }
`

export const StyledNavHorizontal = styled(StyledNav)`
  display: flex;
  margin-bottom: var(--spacer-medium);
  position: relative;
  ${({ scroll }) =>
    scroll &&
    `
    > div {
      overflow: auto;
      padding-bottom: var(--spacer-small) 0;
      -webkit-overflow-scrolling: touch;
    }

    &:after {
      content: '';
      height: 100%;
      width: 25vw;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
      pointer-events: none;
    }

    a {
      margin-right: var(--spacer-medium);
    }
  `}

  a:not(:last-child) {
    margin-right: var(--spacer-medium);
  }
`

export const StyledArticleMeta = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--color-dark-grey);
  display: flex;
  align-items: center;

  .separator {
    width: 1px;
    height: 20px;
    display: inline-block;
    margin: 0 1rem;
    background: var(--color-dark-grey);
  }
`

export const StyledSmallWrapper = styled.div`
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  font-size: ${({ fontSize }) => fontSize && `clamp(2rem, 3vw, ${fontSize})`};
  position: relative;
  z-index: 1;
}
`

export const StyledArticleWrapper = styled(StyledSmallWrapper)`
  > article {
    font-size: clamp(1.8rem, 3vw, 2.2rem);
    margin-bottom: var(--spacer-large);

    ${StyledArticleMeta} {
      justify-content: center;
    }

    a {
      color: inherit;
      transition: color 0.3s;

      &:hover {
        color: var(--color-primary);
      }
    }

    .block-img img {
      max-width: 100%;
    }

    .gatsby-image-wrapper {
      border-radius: var(--border-radius-medium);
      margin-bottom: var(--spacer-medium);
    }

    ul,
    ol {
      margin-left: 1em;

      &:not(:last-child) {
        margin-bottom: 1.2em;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: auto;
    }
  }

  > aside {
    padding: var(--spacer-medium);
    background: var(--color-primary);
    margin-bottom: var(--spacer-large);
    border-radius: var(--border-radius-medium);
    text-align: center;
    box-shadow: 4px 4px 8px var(--color-secondary);

    h2 {
      display: block;
    }
  }
`

export const StyledGrid = styled.div`
  display: grid;
  grid-gap: var(--spacer-medium);
  grid-auto-flow: ${({ autoFlow }) => autoFlow && autoFlow};
  align-items: ${({ alignItems }) => alignItems && alignItems};

  @media ${devices.tablet} {
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  }
`

export const StyledScrollableRow = styled.div`
  display: flex;
  overflow: auto;
  padding: var(--spacer-small) var(--spacer-medium);
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;

  > article {
    min-width: calc(100% - (var(--spacer-medium) * 1.5));
    max-width: calc(100% - (var(--spacer-medium) * 1.5));
    flex: 1;
    margin: 0 var(--spacer-small) calc(var(--spacer-small) / 2) var(--spacer-small);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
    scroll-snap-align: start;

    @media ${devices.tablet} {
      min-width: calc(75% - (var(--spacer-medium) * 1.5));
      max-width: calc(75% - (var(--spacer-medium) * 1.5));
    }

    @media ${devices.laptop} {
      min-width: ${({ count }) => (count > 2 ? `calc(50% - (var(--spacer-medium) * 1.5))` : `50%`)};
      max-width: ${({ count }) => (count > 2 ? `calc(50% - (var(--spacer-medium) * 1.5))` : `50%`)};
    }

    &:first-of-type {
      margin-left: calc(var(--spacer-small) * -1);
    }
    &:last-of-type {
      margin-right: calc(var(--spacer-small) * -1);
    }
  }
`

export const StyledPlaceholder = styled.div`
  height: 0;
  border-radius: var(--border-radius-medium);
  padding-bottom: ${({ height, width }) => `calc((${height} / ${width}) * 100%)`};
  background-color: var(--color-light-grey);
`

export const StyledCard = styled.article`
  &:not(:last-child) {
    margin-bottom: ${({ variant }) => variant === 'row' && 'var(--spacer-small)'};
  }

  a {
    display: flex;
    flex-direction: ${({ variant }) => (variant === 'column' ? 'column' : 'row')};
    align-items: ${({ variant }) => variant === 'row' && 'center'};
    color: inherit;
    text-decoration: none;

    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.03);
      }
      h3 {
        color: var(--color-primary);
      }
    }
  }

  h3 {
    margin: 1.4rem 0 1.8rem 0;
    transition: color 0.3s;
  }

  figure,
  ${StyledPlaceholder} {
    min-width: ${({ variant }) => variant === 'row' && '120px'};
    max-width: ${({ variant }) => variant === 'row' && '120px'};
    overflow: hidden;
    margin-bottom: ${({ variant }) => variant === 'column' && 'var(--spacer-small)'};
    margin-right: ${({ variant }) => variant === 'row' && 'var(--spacer-small)'};

    @media ${devices.laptop} {
      max-width: ${({ variant }) => variant === 'row' && '190px'};
      min-width: ${({ variant }) => variant === 'row' && '190px'};
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    transition: transform 0.3s;
  }
`

export const StyledCardPinned = styled.article`
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  position: relative;

  a {
    color: inherit;
    text-decoration: none;
    position: relative;
    display: block;

    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.03);
      }
    }
  }

  h3 {
    margin-top: 1.4rem;
    color: var(--color-light);
  }

  header {
    width: calc(100% - 8rem);
    left: var(--spacer-medium);
    bottom: var(--spacer-medium);
    position: absolute;
    z-index: 1;
  }

  ${StyledArticleMeta} {
    color: var(--color-light);

    .separator {
      background: var(--color-light);
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(69, 74, 255, 0.85) 20%, rgba(69, 74, 255, 0) 70%);
    pointer-events: none;
    border-radius: var(--border-radius-medium);
  }
`

export const StyledFullWidthSection = styled.section`
  position: relative;
  width: auto;
  max-width: 1000%;
  margin: var(--spacer-medium) calc(50% - 50vw);
  padding: var(--spacer-large) var(--spacer-medium);
  color: ${({ color }) => `var(${color})`};
  background-color: ${({ bgColor }) => `var(${bgColor})`};

  > ${StyledMain} {
    width: 100%;
  }

  .hero-offset &:first-of-type {
    padding-top: calc(var(--menu-height) + var(--spacer-medium) + var(--spacer-large));
    margin-top: calc((var(--menu-height) + var(--spacer-medium) + var(--spacer-large)) * -1);
    margin-bottom: 8rem;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(${watermark});
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 100% 0;
    opacity: ${({ bgImage }) => (bgImage ? bgImage : 0)};
    /* z-index: -1; */
  }
`
export const StyledStickySidebar = styled.div`
  align-self: flex-start;
  position: sticky;
  top: calc(var(--menu-height) + 2rem);
`

export const Button = styled.button`
  font-size: 2rem;
  font-weight: var(--font-body-weight-medium);
  line-height: 1;
  color: ${({ diap }) => (diap ? 'var(--color-light)' : 'var(--color-primary)')};
  text-decoration: none;
  padding: var(--spacer-small) var(--spacer-medium);
  border-radius: 4rem;
  border: 1px solid ${({ diap }) => (diap ? 'var(--color-light)' : 'var(--color-primary)')};
  background: none;
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s, background 0.3s;

  &:hover {
    /* color: var(--color-light); */
    /* background: var(--color-primary); */
    color: ${({ diap }) => (diap ? 'var(--color-primary)' : 'var(--color-light)')};
    background: ${({ diap }) => (diap ? 'var(--color-light)' : 'var(--color-primary)')};
  }
`
