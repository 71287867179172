import { css, createGlobalStyle } from 'styled-components'

import { devices } from '../lib/constants'
import { StyledFullWidthSection } from '../components/styles'

const globalStyles = css`
  :root {
    --color-black: #242124;
    --color-light-grey: #faf5f2;
    --color-dark-grey: #7f7f7f;
    --color-dark-blue: #454aff;
    --color-light-blue: #00cce5;
    --color-dark-yellow: #ff8242;
    --color-light-yellow: #ffcf61;
    --color-dark-pink: #ff8242;
    --color-light-pink: #fa7066;
    --color-light-green: #47c278;
    --color-dark-green: #0a6359;

    --color-primary: var(--color-dark-blue);
    --color-secondary: var(--color-light-blue);
    --color-dark: var(--color-black);
    --color-light: var(--color-light-grey);

    --font-body-weight-regular: 400;
    --font-body-weight-medium: 500;
    --font-body-weight-semi-bold: 600;
    --font-body-weight-bold: 700;

    --font-size-extra-large: 12rem;
    --font-size-h1: 9rem;
    --font-size-h2: 6rem;
    --font-size-h3: 3rem;
    --font-size-h4: 2.6rem;

    --border-radius-small: 0.5rem;
    --border-radius-medium: 1rem;
    --border-radius-large: 2rem;

    --menu-height: 70px;

    --max-layout-width: calc(100vw - var(--spacer-medium));
    --spacer-small: 1rem;
    --spacer-medium: 2rem;
    --spacer-large: 4rem;

    @media ${devices.tablet} {
      --spacer-small: 1.5rem;
      --spacer-medium: 3rem;
      --spacer-large: 6rem;
    }

    @media ${devices.laptop} {
      --spacer-small: 2rem;
      --spacer-medium: 4rem;
      --spacer-large: 8rem;
    }

    @media ${devices.desktop} {
      --max-layout-width: 1300px;
    }
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.8rem;
    line-height: 1.5;
    font-family: canada-type-gibson, sans-serif;
    font-weight: var(--font-body-weight-regular);
    font-style: normal;
    box-sizing: border-box;

    text-rendering: optimizeLegibility;
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* overflow: auto; */
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: var(--font-body-weight-medium);
    line-height: 1.05;
    margin-bottom: 0.2em;
  }

  h1,
  .header-size-large {
    font-size: clamp(4rem, 5vw, var(--font-size-h1));
    font-weight: var(--font-body-weight-semi-bold);
  }

  h2 {
    font-size: clamp(3rem, 3vw, var(--font-size-h2));
  }

  h3 {
    font-size: clamp(2.4rem, 3vw, var(--font-size-h3));
  }

  h4 {
    font-size: clamp(2rem, 3vw, var(--font-size-h4));
  }

  p:not(:last-child) {
    margin-bottom: 1.2em;
  }

  .header-size-large {
    margin-bottom: var(--spacer-medium);
  }

  [class*='font-gradient'] {
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    margin-bottom: var(--spacer-medium);
  }

  .font-gradient-blue {
    background-image: linear-gradient(to bottom right, var(--color-dark-blue), var(--color-light-blue));
  }
  .font-gradient-yellow {
    background-image: linear-gradient(to bottom right, var(--color-dark-yellow), var(--color-light-yellow));
  }
  .font-gradient-green {
    background-image: linear-gradient(to bottom right, var(--color-dark-green), var(--color-light-green));
  }
  .font-gradient-pink {
    background-image: linear-gradient(to bottom right, var(--color-dark-pink), var(--color-light-pink));
  }
  .font-gradient-grey {
    background-image: linear-gradient(to bottom right, var(--color-light-grey), white);
  }

  .hero-offset &:first-of-type {
    ${StyledFullWidthSection} + ${StyledFullWidthSection} {
      margin-top: -8rem;
    }
  }

  strong {
    font-weight: var(--font-body-weight-semi-bold);
  }

  .header-breakout {
    margin: 4rem calc(50% - (var(--max-layout-width) / 2));
    text-align: center;
    display: block;
  }
`

export const GlobalStyles = createGlobalStyle`${globalStyles}`

export default globalStyles
